import React, { useState } from "react";
import Wizard from "~components/forms/elements/Wizard";
import Description from "~components/forms/elements/Description";
import PrevButton from "~components/forms/elements/PrevButton";
import NextButton from "~components/forms/elements/NextButton";
import MainData from "~components/forms/blocks/MainData";
import CallBack from "~components/forms/blocks/CallBack";
import Comment from "~components/forms/blocks/Comment";
import Checkbox from "~components/forms/elements/Checkbox";
import H4 from "~components/typography/H4";

const SpeakerForm: React.FC = () => {
	const [step, setStep] = useState(0);
	const formName = "Speaker";

	const prevStep = () => {
		setStep((prevCount) => prevCount - 1);
	};

	const nextStep = () => {
		setStep((prevCount) => prevCount + 1);
	};

	return (
		<form name={formName} method="POST" data-netlify="true" action="/anfrage-gesendet/">
			<input type="hidden" name="form-name" value={formName} />
			<Wizard currentStep={step}>
				<MainData prevStep={prevStep} nextStep={nextStep} step={step} />
				<CallBack prevStep={prevStep} nextStep={nextStep} />
				<Comment prevStep={prevStep} nextStep={nextStep} />
			</Wizard>
		</form>
	);
};

export default SpeakerForm;
