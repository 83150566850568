// Packages
import React from "react";
import { graphql } from "gatsby";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";

// Components
import Layout from "~components/layouts/Layout";
import H1 from "~components/typography/H2";
import Enquiery from "~components/layouts/Enquiery";
import SpeakerForm from "~components/forms/SpeakerForm";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	imageHeader: IGatsbyImageData;
	profileImage: IGatsbyImageData;
	imageTestmonial: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	return (
		<Layout
			location={location}
			title="Speaker anfragen"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			hideHeader
		>
			<Enquiery>
				<H1 display className="mb-5">
					Speaker anfragen
				</H1>
				<SpeakerForm />
			</Enquiery>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		seoImage: file(relativePath: { eq: "placeholder-meta.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		imageHeader: file(relativePath: { eq: "pages/coaching/coaching_header.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageTestmonial: file(relativePath: { eq: "pages/coaching/coaching_testmonial.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
